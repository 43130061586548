.logo {
    width: 85%;
    margin: 16px 0;
}

.navPromptWrapper {
    border-bottom: 1px solid #ffffff33;
    padding: 10px;
    height: calc(100vh - 360px);
    overflow-y: auto;
  }