.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: center / cover no-repeat url('/src/assets/images/subscription_popup_bg.png');
  color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 6rem;
  text-align: center;
  position: relative;
  min-width: 250px;
  max-width: 720px;
  min-height: 450px;
  z-index: 1000;
  font-family: 'monospace';
}

.contentWrapper:hover .closeBtn {
  opacity: 1;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  transition: transform 0.2s ease, opacity 0.5s;
}

.closeBtn:hover {
  transform: scale(1.2);
}  

.logo {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.cardHeader {
  pointer-events: none;
}

.cardTitle {
  font-weight: 400;
  margin: 0;
}

.cardPrice {
  font-size: 3rem;
  margin: 1rem 0;
}

.cardDescription {
  margin: 0;
  line-height: 1.6;
  padding: 0 3rem;
  pointer-events: none;
}

.cardButton {
  background: none;
  border: 1px solid #fff;
  color: white;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 40px;
}

@media screen and (max-width: 1024px) {
  .contentWrapper {
    padding: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .contentWrapper {
    padding: 2rem;
    font-size: 14px;
  }

  .logo {
    width: 100%;
    margin-bottom: 20px;
  }

  .cardDescription {
    padding: 1rem;
  }

  .cardButton {
    margin-top: 20px;
  }
}

@media screen and (max-width: 430px) {
  .contentWrapper {
    padding: 1rem;
  }

  .cardDescription {
    padding: 0;
  }
}
