.contentWrapper {
  width: 90%;
  height: 90%;
  padding: 20px;
  padding-top: 0;
  border-radius: 8px;
  background-color: var(--bg-color);
  overflow: hidden;
  z-index: 1000;
}

.contentHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  margin: 10px 0;
  background-color: var(--bg-color);
}

.btn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.btn:hover {
  transform: scale(1.2);
}

.content {
  height: calc(100% - 2rem - 30px);
  overflow-y: scroll;
}

.image {
  width: 100%;
}