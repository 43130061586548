.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
    background-color: #363636;
  }

  @media screen and (max-width: 1024px) {
    .avatar {
        width: 30px;
        height: 30px;
      }
  }