.wrapper {
    font-family: monospace;
    display: flex;
    column-gap: 16px;
    /* width: 244px; */
}

@media (min-width: 768px) {
    .wrapper {
        min-width: 244px;
    }
}

.messageInfo {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.username, .botname {
    position: relative;
    max-width: 155px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: .7em;
}

.username::after {
    content: '>';
    position: absolute;
    top: 0;
    right: 0;
}

.botname::after {
    content: '>';
}

.timestamp {
    color: #363636;
    font-weight: 700;
}

@media screen and (max-width: 1024px) {
    .userSVG {
        transform: scale(0.5);
    }
}
